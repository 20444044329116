import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./CV.module.css";
import Lines from "../../components/lines/Lines";
import locales from "../../context/locales.json";
import CV1 from "../../assets/madrak/photo12922273992.jpg";
import CV2 from "../../assets/madrak/photo12922273994.jpg";
import CV3 from "../../assets/madrak/photo12922274016.jpg";

const CV = ({ refCV, W }) => {
  return (
    <Fragment>
      <Lines W={W} />
      <Box className={classess.CV} ref={refCV}>
        <span className={classess.Title}>{locales.CV}</span>
        <span className={classess.SubTitle}>{locales.CV2}</span>
        {/* <Box className={classess.CVBox}>
          <img src={CV1} alt="" className={classess.Madrak1} />
          <img src={CV2} alt="" className={classess.Madrak2} />
          <img src={CV3} alt="" className={classess.Madrak3} />
        </Box> */}
        <Box className={classess.CVDetail}>
          <Box className={classess.Span1}>
            <img src={CV1} alt="" className={classess.Madrak1} />
            <span className={classess.DetailTitle}>{locales.Gavahi}</span>
            <span className={classess.DT}>{locales.Fani1}</span>
          </Box>
          <Box className={classess.Span2}>
            <img src={CV2} alt="" className={classess.Madrak2} />
            <span className={classess.DetailTitle}>{locales.Gavahi}</span>
            <span className={classess.DT}>{locales.Fani2}</span>
          </Box>
          <Box className={classess.Span3}>
            <img src={CV3} alt="" className={classess.Madrak3} />
            <span className={classess.DetailTitle}>{locales.Gavahi}</span>
            <span className={classess.DT}>{locales.Fani3}</span>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CV;
